import React, { useState, useEffect, useCallback, useRef } from 'react';

const ElectricityOverlay = () => {
  const [particles, setParticles] = useState([]);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);

  const createParticle = useCallback((x, y) => {
    return {
      x,
      y,
      vx: Math.random() * 2 - 1,
      vy: Math.random() * 2 - 1,
      lifetime: Math.random() * 20 + 10,
    };
  }, []);

  const updateMousePosition = useCallback((e) => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setMousePosition({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      });
    }
  }, []);

  useEffect(() => {
    window.addEventListener('mousemove', updateMousePosition);
    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, [updateMousePosition]);

  useEffect(() => {
    const animationFrame = requestAnimationFrame(() => {
      setParticles((prevParticles) => {
        const newParticles = prevParticles
          .map((p) => ({
            ...p,
            x: p.x + p.vx,
            y: p.y + p.vy,
            lifetime: p.lifetime - 1,
          }))
          .filter((p) => p.lifetime > 0 && p.x >= 0 && p.y >= 0 && 
                         p.x <= containerRef.current?.clientWidth && 
                         p.y <= containerRef.current?.clientHeight);

        if (Math.random() < 0.3) {
          newParticles.push(createParticle(mousePosition.x, mousePosition.y));
        }

        return newParticles;
      });
    });

    return () => cancelAnimationFrame(animationFrame);
  }, [particles, mousePosition, createParticle]);

  return (
    <div
      ref={containerRef}
      className='h-100'
      style={{ 
        zIndex: 1000, 
        position: 'absolute', 
        top: 0, 
        left: 0, 
        right: 0,
        bottom: 0,
        pointerEvents: 'none' 
      }}>
      {particles.map((p, index) => (
        <div
          key={index}
          style={{
            position: 'absolute',
            left: p.x,
            top: p.y,
            width: '4px',
            height: '4px',
            backgroundColor: `rgba(0, 191, 255, ${p.lifetime / 30})`,
            boxShadow: '0 0 2px #00bfff',
          }}
        />
      ))}
    </div>
  );
};

export default ElectricityOverlay;